<template>
  <div>
    <v-expansion-panels class="mb-4">
      <v-expansion-panel v-for="(currency, i) in currencies" :key="i">
        <v-expansion-panel-header color="grey600">
          <span v-if="currency.name">
            <span class="font-weight-semibold  mr-1" v-text="currency.name" />
            <span class="text-uppercase primary400--text">({{ currency.code }})</span>
          </span>
          <span v-else class="text--disabled">Untitled</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey600">
          <v-form class="mb-4" @submit.prevent>
            <v-row dense>
              <v-col cols="12" md="6">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Name</label>
                  <v-text-field
                    v-model="currency.name" filled dense color="primary500"
                    placeholder="Euro"
                  />
                </fieldset>
              </v-col>

              <v-col cols="12" md="6">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Code</label>
                  <v-text-field
                    v-model="currency.code" filled dense color="primary500"
                    placeholder="eur"
                    :rules="[v => v.length === 3 || '3 chars long']"
                  />
                </fieldset>
              </v-col>
            </v-row>
          </v-form>

          <div class="text-right">
            <v-btn
              color="red" class="text-none" small
              :disabled="loading.currencies"
              @click="onDelete(i)"
            >
              <v-icon small class="mr-1">
                mdi-trash
              </v-icon>
              Delete
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-right">
      <v-btn
        color="grey100" outlined class="text-none mr-2"
        small :disabled="loading" @click="onAdd"
      >
        <v-icon small class="mr-1">
          mdi-plus
        </v-icon>
        Add new
      </v-btn>

      <v-btn
        :color="hasChanges ? 'green--gradient' : 'grey200'" class="text-none"
        light small :loading="loading"
        @click="onSave"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
const DEFAULT_CURRENCY_FORM = () => ({
  name: '',
  code: '',
})

export default {
  props: {
    loading: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currencies: [],
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.value) !== JSON.stringify(this.currencies)
    },
  },
  watch: {
    value: {
      handler(val) {
        this.currencies = structuredClone(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onAdd() {
      this.currencies.push(DEFAULT_CURRENCY_FORM())
    },
    onDelete(i) {
      this.currencies.splice(i, 1)
    },
    onSave() {
      this.$emit('save', this.currencies)
    },
  },
}
</script>
