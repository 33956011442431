<template>
  <div>
    <v-expansion-panels class="mb-4">
      <v-expansion-panel v-for="(market, i) in markets" :key="i">
        <v-expansion-panel-header color="grey600">
          <span v-if="market.name">
            <span class="font-weight-semibold  mr-1" v-text="market.name" />
            <span class="text-uppercase primary400--text">({{ market.slug }})</span>
          </span>
          <span v-else class="text--disabled">Untitled</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey600">
          <v-form class="mb-4" @submit.prevent>
            <v-row dense>
              <v-col cols="12">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Name</label>
                  <v-text-field
                    v-model="market.name" filled dense color="primary500"
                    placeholder="North America"
                  />
                </fieldset>
              </v-col>

              <v-col cols="12" md="6">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Slug</label>
                  <v-text-field
                    v-model="market.slug" filled dense color="primary500"
                    placeholder="na"
                  />
                </fieldset>
              </v-col>

              <v-col cols="12" md="6">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Currency</label>
                  <v-autocomplete
                    v-model="market.currency"
                    :items="currencies"
                    item-text="code"
                    item-value="code"
                    filled clearable dense
                    placeholder="USD"
                  />
                </fieldset>
              </v-col>
            </v-row>

            <div class="text-right">
              <v-btn
                color="red" class="text-none" small
                :disabled="loading.markets"
                @click="onDelete(i)"
              >
                <v-icon small class="mr-1">
                  mdi-trash
                </v-icon>
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-right">
      <v-btn
        color="grey100" outlined class="text-none mr-2"
        small :disabled="loading" @click="onAdd"
      >
        <v-icon small class="mr-1">
          mdi-plus
        </v-icon>
        Add new
      </v-btn>

      <v-btn
        :color="hasChanges ? 'green--gradient' : 'grey200'" class="text-none"
        light small :loading="loading"
        @click="onSave"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
const DEFAULT_FORM = () => ({
  name: '',
  slug: '',
  currency: '',
})

export default {
  props: {
    loading: Boolean,
    currencies: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      markets: [],
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.value) !== JSON.stringify(this.markets)
    },
  },
  watch: {
    value: {
      handler(val) {
        this.markets = structuredClone(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onAdd() {
      this.markets.push(DEFAULT_FORM())
    },
    onDelete(i) {
      this.markets.splice(i, 1)
    },
    onSave() {
      this.$emit('save', this.markets)
    },
  },
}
</script>
