<template>
  <v-container class="is-md py-12 px-2">
    <h3 class="text-h6 font-weight-bold">
      Regional configs
    </h3>
    <p class="text-caption mb-6">
      You will almost never have a reason to touch anything here.
      If you do, you better know what you're doing.
    </p>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-4" color="grey800">
          <h4 class="text-body-1 primary500--text font-weight-bold">
            Currencies
          </h4>
          <p class="text-caption">
            Changing these can brick the whole site, so don't :)
          </p>

          <Currencies
            :value="currencies"
            :loading="loading.currencies"
            @save="onSetCurrencies"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="pa-4" color="grey800">
          <h4 class="text-body-1 primary500--text font-weight-bold">
            Markets
          </h4>
          <p class="text-caption">
            Changing these can brick the whole site, so don't :)
          </p>

          <Markets
            :value="markets"
            :currencies="currencies"
            :loading="loading.markets"
            @save="onSetMarkets"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-container class="is-sm">
      <v-divider class="my-8" />
    </v-container>

    <h3 class="text-h6 font-weight-semibold">
      Supported countries
    </h3>
    <p class="text-body-2 mb-6">
      Manage the countries we ship to or allow crypto cashouts in.
    </p>

    <Countries :markets="markets" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import Currencies from './components/currencies'
import Markets from './components/markets'
import Countries from './components/countries'

export default {
  metaInfo: {
    title: 'Regional configs',
  },
  components: {
    Markets,
    Currencies,
    Countries,
  },
  data() {
    return {
      loading: {
        markets: false,
        currencies: false,
      },
      currencies: [],
      markets: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  async created() {
    this.onGetCurrencies()
    this.onGetMarkets()
  },
  methods: {
    //  currencies
    async onGetCurrencies() {
      try {
        this.loading.currencies = true
        this.currencies = await this.$socket.request('admin.currencies.get')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.currencies = false
      }
    },
    async onSetCurrencies(currencies) {
      try {
        this.loading.currencies = true
        await this.$socket.request('admin.currencies.set', currencies.map(i => ({
          code: i.code.toLowerCase(),
          name: i.name,
        })))
        this.$toast.success('Currencies saved')
        this.onGetCurrencies()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.currencies = false
      }
    },
    // markets
    async onGetMarkets() {
      try {
        this.loading.markets = true
        this.markets = await this.$socket.request('admin.markets.get')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.markets = false
      }
    },
    async onSetMarkets(markets) {
      try {
        this.loading.markets = true
        await this.$socket.request('admin.markets.set', markets.map(i => ({
          _id: i._id,
          slug: i.slug.toLowerCase(),
          name: i.name,
          currency: i.currency.toLowerCase(),
        })))
        this.$toast.success('Markets saved')
        this.onGetMarkets()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.markets = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-caption {

}
</style>
