<template>
  <div>
    <v-row align="end">
      <v-col class="d-flex align-center">
        <h4 class="text-caption mr-2 lh-1 text-uppercase font-weight-bold">
          Only show
        </h4>
        <v-chip-group
          v-model="options.filter.markets"
          column multiple color="primary500"
        >
          <v-chip
            v-for="market in markets" :key="market._id"
            label small
            :value="market._id"
            class="text-uppercase font-weight-medium text-caption"
          >
            {{ market.slug }}
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-spacer />

      <v-col class="d-flex align-center">
        <v-text-field
          v-model="search" filled dense outlined
          prepend-inner-icon="mdi-magnify"
          class="mr-2"
          placeholder="Search country..."
          hide-details
        />
        <v-btn
          light color="primary--gradient" :loading="loading.list"
          class="px-3 unwidth" @click="fetchApiData"
        >
          <v-icon class="text-h6">
            mdi-sync
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="data-table mb-8 mt-2"
      item-key="_id"
      :headers="headers"
      :items="entries"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
      :server-items-length="total"
      :expanded="expanded"
      :loading="loading.list"
      @pagination="onPagination"
    >
      <template #item.name="{ item, value }">
        <span class="twf mr-2" :class="`twf-${item.code}`" />
        <span v-text="value" />
        <span class="ml-1 text--secondary text-uppercase text-caption">({{ item.code }})</span>
      </template>

      <template #item.market="{ value }">
        <v-chip
          v-if="value"
          label small color="primary--gradient" light
          class="mr-2 px-2 font-weight-bold text-uppercase"
        >
          {{ value.slug }}
        </v-chip>
        <v-tooltip v-else top color="red">
          <template #activator="{ on }">
            <v-icon color="red" v-on="on">
              mdi-alert
            </v-icon>
          </template>
          <span>Broken Market link! Fix ASAP!</span>
        </v-tooltip>
      </template>

      <template #item.perms="{ item }">
        <v-chip v-if="item.canShip" label small class="mr-2">
          Shipping
        </v-chip>
        <v-chip v-if="item.canCrypto" label small>
          Crypto
        </v-chip>

        <!-- <span v-if="item.canShip" class="mr-1">Shippable</span>
        <span v-if="item.canCrypto">Crypto</span> -->

        <span v-if="!item.canShip && !item.canCrypto" class="text--disabled">&mdash;</span>
      </template>

      <template #item.createdAt="{ value }">
        <v-tooltip top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
          </template>
          <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>

      <template #item.actions="{ item }">
        <v-icon small class="primary500--text mr-2 link" @click="onExpandItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon
          small class="red--text link"
          @click="onDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- transaction info -->
      <template #expanded-item="{ headers: head, item }">
        <td :colspan="head.length" class="grey800 py-3 data-detail">
          <CountryForm
            :value="item"
            :markets="markets"
            :loading="loading.create"
            @submit="onSetCountry"
          />
        </td>
      </template>
    </v-data-table>

    <v-card class="pa-4" color="grey800">
      <h4 class="text-body-1 primary500--text mb-4 font-weight-bold">
        Add new country
      </h4>

      <CountryForm
        v-model="newCountryForm"
        :markets="markets"
        :loading="loading.create"
        is-new
        @submit="onSetCountry"
      />
    </v-card>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'

import CountryForm from './CountryForm'

const newCountryFormDefault = () => ({
  name: '',
  code: '',
  canCrypto: false,
  canShip: false,
  market: null,
})

export default {
  components: {
    CountryForm,
  },
  props: {
    markets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: {
        list: false,
        create: false,
      },
      expanded: [],
      entries: [],
      search: '',
      total: 0,
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
        page: 1,
        filter: {
          markets: [],
          search: '',
        },
      },
      headers: [
        { text: 'Country', value: 'name', align: 'start' },
        { text: 'Market', value: 'market', sortable: false },
        { text: 'Perms', value: 'perms', sortable: false },
        { text: 'Created', value: 'createdAt', align: 'end' },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      newCountryForm: newCountryFormDefault(),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search(val) {
      this.onSearchInput(val)
    },
  },
  methods: {
    onSearchInput: debounce(function fn(val) {
      this.options.page = 1
      this.options.filter.search = val
    }, 500),
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item._id === expandedItem?._id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    async fetchApiData() {
      const {
        sortBy, sortDesc, page, itemsPerPage, filter,
      } = this.options

      try {
        this.loading.list = false
        const { entries, total } = await this.$socket.request('admin.countries.get', {
          page,
          filter,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onSetCountry(form) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.countries.set', form)

        this.newCountryForm = newCountryFormDefault()
        this.$toast.success(`Country "${form.name}" created/updated successfully.`)

        this.fetchApiData()
        this.expanded = []
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onDelete(country) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.countries.delete', country._id)

        this.$toast.success(`Country "${country.name}" deleted successfully.`)
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
