<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="6">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Country name</label>
          <v-text-field
            v-model="form.name" filled dense color="primary500"
            placeholder="Switzerland"
          />
        </fieldset>
      </v-col>

      <v-col cols="12" md="3">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Code</label>
          <v-text-field
            v-model="form.code" filled dense color="primary500"
            placeholder="ch" hint="2 letters, ISO-3166 code"
            :rules="[v => v.length === 2 || '2 chars long']"
          />
        </fieldset>
      </v-col>

      <v-col cols="12" md="3">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Market</label>
          <v-select
            v-model="form.market"
            :items="markets"
            item-text="name"
            item-value="_id"
            filled dense
            placeholder="EU"
          />
        </fieldset>
      </v-col>

      <v-col cols="12" md="6">
        <v-card color="grey500" class="px-4 py-2" @click="form.canCrypto = !form.canCrypto">
          <v-switch
            :input-value="form.canCrypto"
            color="primary500" class="pa-0 ma-0"
            inset hide-details
          >
            <template #label>
              <div>
                <h4 class="text-body-2 grey100--text">
                  Crypto cashouts
                </h4>
                <p class="text-caption mb-0">
                  Users can cash out to crypto in this country
                </p>
              </div>
            </template>
          </v-switch>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card color="grey500" class="px-4 py-2" @click="form.canShip = !form.canShip">
          <v-switch
            :input-value="form.canShip"
            color="primary500" class="pa-0 ma-0"
            inset hide-details
          >
            <template #label>
              <div>
                <h4 class="text-body-2 grey100--text">
                  Shippable
                </h4>
                <p class="text-caption mb-0">
                  We can ship items to this country
                </p>
              </div>
            </template>
          </v-switch>
        </v-card>
      </v-col>

      <v-col cols="12" class="text-right">
        <v-btn
          light class="font-weight-bold"
          color="green--gradient"
          :loading="loading"
          @click="onSubmit"
        >
          <v-icon small class="mr-1">
            mdi-check-bold
          </v-icon>
          {{ isNew ? 'Create' : 'Update' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Object,
      default: () => {},
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    markets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: null,
    }
  },
  watch: {
    value: {
      handler(val) {
        if (JSON.stringify(val) === JSON.stringify(this.form)) return
        this.form = { ...val }
      },
      immediate: true,
      deep: true,
    },
    form: {
      handler(val) {
        this.$emit('input', { ...val })
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
